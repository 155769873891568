import { gql } from "@apollo/client";

const autorisation = gql`
  mutation autorisation($ip: String!) {
    autorisation(ip: $ip) {
      success
      code
      jwtToken
    }
  }
`;

export default autorisation;
