export const mutationCodes = {
  success: "SUCCESS",
  unauthorized: "UNAUTHORIZED",
  accessDenied: "ACCESS_DENIED",
  numberAdditionsLimited: "NUMBER_ADDITIONS_LIMITED"
};
export const test = {
  success: "SUCCESS",
  unauthorized: "UNAUTHORIZED",
  accessDenied: "ACCESS_DENIED"
};

export const postTypes = {
  announcements: "ANNOUNCEMENT",
  offerVacancy: "OFFER_VACANCY",
  lookingJob: "LOOKING_JOB",
  rentProperty: "RENT_PROPERTY",
  carrier: "CARRIER"
};

export const createPostNameTabs = {
  announcement: "announcement",
  offerVacancy: "offer-vacancy",
  lookingJob: "looking-for-a-job",
  rentalHousing: "rental-housing",
  carriers: "carriers"
};

export const typeField = {
  city: "city",
  typeRoom: "typeRoom",
  postIndex: "postIndex",
  rentalPeriod: "rentalPeriod",
  price: "price",
  numberOfRoom: "numberOfRoom",
  numberOfBathroom: "numberOfBathroom",
  carParking: "carParking",
  garden: "garden",
  utilitiesIncluded: "utilitiesIncluded",
  categoryProfession: "categoryProfession",
  profession: "profession",
  workForAMarriedCouple: "workForAMarriedCouple",
  workForWomen: "workForWomen",
  youCanWorkOnWeekends: "youCanWorkOnWeekends",
  contractIn: "contractIn",
  wifi: "contractIn"
};
