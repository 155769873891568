import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import { getQuestionUrl, homeUrl } from "./routes";
import { PublicAppRoute } from "./routes/AppRoutes/AppRoutes";
import views from "./views";

const { AddQuestion, Question } = views;

const AppRouter = () => (
  <Router>
    <Switch>
      <>
        {/* AUTHORIZED USER ROUTES */}
        <PublicAppRoute exact path={homeUrl} component={AddQuestion} />
        <PublicAppRoute path={getQuestionUrl(":link")} component={Question} />
      </>
    </Switch>
  </Router>
);

export default AppRouter;
