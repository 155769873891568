import { gql } from "@apollo/client";

const createQuestionResponse = gql`
  mutation createQuestionResponse($input: QuestionResponseInput!) {
    createQuestionResponse(input: $input) {
      success
      code
    }
  }
`;

export default createQuestionResponse;
