import Question from "../components/Question";
import Layout from "./Layout/Layout";

const PostView = () => (
  <Layout>
    <Question />
  </Layout>
);

export default PostView;
