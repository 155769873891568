import PropTypes from "prop-types";
import { types } from "react-alert";

import ErrorAlert from "./ErrorAlert";
import SuccessAlert from "./SuccessAlert";

const AlertLayout = ({ options, message, close }) => {
  if (options.type === types.SUCCESS) {
    return <SuccessAlert message={message} close={close} />;
  }
  if (options.type === types.ERROR) {
    return <ErrorAlert message={message} close={close} />;
  }
  return null;
};
AlertLayout.propTypes = {
  close: PropTypes.func.isRequired,
  message: PropTypes.any,
  options: PropTypes.object
};

export default AlertLayout;
