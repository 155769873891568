import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Item = ({ title, number, link }) => (
  <Link to={link} className="poll-item">
    <div className="poll-item_info poll-item_infoFull">
      <span className="poll-item_number">{number}</span>
      <p className="poll-item_content">{title}</p>
    </div>
  </Link>
);

Item.propTypes = {
  title: PropTypes.string,
  number: PropTypes.string,
  link: PropTypes.string
};

export default Item;
