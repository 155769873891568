import AddQuestion from "./AddQuestion";
import Home from "./Home";
import NoPage from "./NoPage";
import Question from "./Question";

export default {
  NoPage,
  Question,
  AddQuestion,
  Home
};
