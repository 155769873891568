import PropTypes from "prop-types";

import { classNames } from "../../../utility";

const Select = ({ options, value, onChange, id, label, error }) => (
  <>
    <label htmlFor={id} className="form-label">
      {label}
    </label>
    <select
      className={classNames("form-select", error ? "border border-danger" : "")}
      id={id}
      value={value || options[0]}
      aria-label="Default select example"
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((option) => (
        <option key={option.id} value={option.id}>
          {option.title}
        </option>
      ))}
    </select>
  </>
);
Select.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      title: PropTypes.any,
      value: PropTypes.any
    })
  ),
  value: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.any,
  error: PropTypes.string
};
Select.defaultProps = {
  options: []
};
export default Select;
