import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import PropTypes from "prop-types";

const DatetimePicker = ({ value, onChange }) => (
  <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={1}>
      <DateTimePicker
        value={value}
        onChange={onChange}
        minDate={new Date()}
        inputFormat="yyyy/MM/dd HH:mm"
        renderInput={(params) => (
          <TextField {...params} className="data-piker-form-check-input" />
        )}
      />
    </Stack>
  </LocalizationProvider>
);

DatetimePicker.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func
};
export default DatetimePicker;
