/* eslint-disable react/prop-types */
import { gql, useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";

import FallbackSpinner from "../../components/layout/Loading/Loading";
import { setJWT } from "../localStorage";

export const CURRENT_USER = gql`
  {
    currentUser {
      isAutorisation
      ip
    }
  }
`;
export const UserContext = React.createContext({});
export const UserContextProvider = ({ children }) => {
  const { loading, error, data, refetch } = useQuery(CURRENT_USER, {
    errorPolicy: "ignore"
  });
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (!loading) {
      const currentUser =
        !error && data && data.currentUser
          ? {
              ip: data.currentUser.ip,
              isAutorisation: data.currentUser.isAutorisation
            }
          : false;
      setUser(currentUser);
    }
  }, [error, data, loading]);

  if (user === null) return <FallbackSpinner />;

  const login = (jwt) => {
    setJWT(jwt);
    refetch();
  };

  const logout = () => {
    setJWT(null);
    setUser(null);
    refetch();
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};
