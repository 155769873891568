import { API_URL } from "../config";

export const homeUrl = "/";
export const questionUrl = "/question";
export const getQuestionUrl = (link) => `/question/${link}`;

export const apiUrl = API_URL;

const routes = {
  homeUrl,
  apiUrl,
  questionUrl
};

export default routes;
