import { gql } from "@apollo/client";

const createQuestionResponseVoit = gql`
  mutation createQuestionResponseVoit($input: QuestionResponseVoitInput!) {
    createQuestionResponseVoit(input: $input) {
      success
      code
    }
  }
`;

export default createQuestionResponseVoit;
