/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import "../../../css/bootstrap.min.css";

import { useMutation } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import autorisation from "../../../graphql/mutation/autorisation";
import { UserContext } from "../../../utility/context/User";
import Footer from "../Footer";
import Header from "../Header";

const publicIp = require("react-public-ip");

const getIp = async (setIp) => {
  const i = (await publicIp.v4()) || "";
  setIp(i || "");
};
const authentication = async ({ ip, login, autorisationMutation }) => {
  const { data } = await autorisationMutation({
    variables: {
      ip
    }
  });
  login(data.autorisation.jwtToken);
};

const Layout = ({ children }) => {
  const [ip, setIp] = useState();
  const [autorisationMutation] = useMutation(autorisation);
  const { user, logout, login } = useContext(UserContext);
  const { pathname } = useLocation();
  useEffect(() => {
    if (!user && !ip) {
      getIp(setIp);
    }
  }, [user]);
  useEffect(() => {
    if (ip) {
      authentication({ ip, login, autorisationMutation });
    }
  }, [ip]);

  return (
    <div className="wrapper">
      <Header {...{ user, pathname, logout }} />
      <main className="main-content">
        <div className="container">{children}</div>
      </main>
      <Footer />
      <div className="modal-mobile">
        <div className="modal-mobile-bg"></div>
      </div>
    </div>
  );
};
export default Layout;
