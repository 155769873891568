import AddQuestion from "../components/AddQuestion";
import Layout from "./Layout/Layout";

const PeopleView = () => (
  <Layout>
    <AddQuestion />
  </Layout>
);

export default PeopleView;
