import "./index.css";

import React from "react";

const Loading = () => (
  <>
    <div className="top-0 left-0 right-0 bottom-0 w-full h-60 z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center">
      <div className="loader ease-linear rounded-full border-4 border-transparent border-t-4 h-12 w-12 mb-4"></div>
    </div>
  </>
);

export default Loading;
