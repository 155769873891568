import { gql } from "@apollo/client";

const createQuestion = gql`
  mutation createQuestion($input: QuestionInput!) {
    createQuestion(input: $input) {
      success
      code
      link
    }
  }
`;

export default createQuestion;
