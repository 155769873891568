const Footer = () => (
  <footer className="footer">
    <div className="container">
      <div className="footer-inner">
        <p className="copyright">Всі права захищені upask.top 2022</p>
        <div className="payment-logo">
          <span>
            <img src="/assets/img/visa.svg" alt="" />
          </span>
          <span>
            <img src="/assets/img/paypal.svg" alt="" />
          </span>
          <span>
            <img src="/assets/img/mastercard.svg" alt="" />
          </span>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
