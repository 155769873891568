import React from "react";

import Router from "./Router";
import { UserContextProvider } from "./utility/context/User";

const App = () => (
  <UserContextProvider>
    <Router />
  </UserContextProvider>
);

export default App;
