export const classNames = (...classes) => classes.filter(Boolean).join(" ");

export const validateEmail = (email) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return pattern.test(email);
};

export const validateUrl = (string) =>
  !!string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );

export const isAgency = (isAgencyType, t) =>
  isAgencyType ? t("post.agency") : t("post.privatePerson");

export const concatObj = (objs) => Object.assign({}, ...objs);

export const getValues = (value, code) => {
  if (code) return value;
  return "";
};

export const getValuesBoolean = (value, code) => {
  if (code) return value;
  return false;
};

export const getValueId = (value, code) => {
  if (code) return value;
  return null;
};

export const getCreatePostTypeUser = (isAgencyUser, code) => {
  if (code) return isAgencyUser ? "agency" : "private";
  return "private";
};
