import { useState } from "react";
import { Link } from "react-router-dom";

import { homeUrl } from "../../routes";

const Header = () => {
  const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);

  return (
    <>
      <header className="header">
        <div className="container">
          <div className="header-inner">
            <div className="header-logo">
              <Link to={homeUrl}>
                <img src="/assets/img/logo.svg" alt="" />
              </Link>
            </div>
            <ul className="main-menu d-none d-lg-flex">
              <li className="has-children">
                <Link to="#">Про сервіс</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="#">Під меню 1</Link>
                  </li>
                  <li>
                    <Link to="#">Під меню 2</Link>
                  </li>
                  <li>
                    <Link to="#">Під меню 3</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#">Публічна оферта</Link>
              </li>
              <li>
                <Link to="#">Технічна підтримка</Link>
              </li>
            </ul>
            <p className="header-btn d-none d-lg-block">
              <Link to={homeUrl} className="btn btn-lg btn-primary">
                Додати тему
              </Link>
            </p>
            <div className="header-toggle d-lg-none">
              <button
                id="menu-toggled"
                onClick={() => setIsShowMobileMenu(true)}
              >
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </header>
      <p className="header-btn d-lg-none text-center">
        <Link to={homeUrl} className="btn btn-lg btn-primary">
          Додати тему
        </Link>
      </p>
      <div className={`modal-mobile ${isShowMobileMenu ? "show" : ""}`}>
        <div className="modal-mobile-bg"></div>
        <div className="modal-mobile-wrap">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-10">
                <div className="header-logo">
                  <Link to="#">
                    <img src="/assets/img/logo.svg" alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-2">
                <button
                  className="modal-mobile-close"
                  onClick={() => setIsShowMobileMenu(false)}
                ></button>
              </div>
            </div>
            <ul className="main-menu">
              <li className="has-children">
                <Link to="#">Про сервіс</Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="#">Під меню 1</Link>
                  </li>
                  <li>
                    <Link to="#">Під меню 2</Link>
                  </li>
                  <li>
                    <Link to="#">Під меню 3</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="#">Публічна оферта</Link>
              </li>
              <li>
                <Link to="#">Технічна підтримка</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
