import "./i18n";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { Provider as AlertProvider } from "react-alert";
import ReactDOM from "react-dom";

import App from "./App";
import graphqlClient from "./utility/graphqlClient";
import AlertLayout from "./views/Layout/AlertLayout";

const alertOptions = {
  offset: "25px",
  timeout: 3000
};

ReactDOM.render(
  <ApolloProvider client={graphqlClient}>
    <AlertProvider template={AlertLayout} {...alertOptions}>
      <App />
    </AlertProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
